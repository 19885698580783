/**
 * Fetches JSON data from a URL
 * @param {string} url - The URL to fetch JSON from
 * @returns {Promise<Object>} - The parsed JSON data
 */
export default async (url) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      const error = new Error(`Failed to fetch JSON: ${response.status} ${response.statusText}`);
      error.statusCode = response.status;
      throw error;
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error fetching JSON:', error);
    throw error;
  }
};

