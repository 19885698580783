import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, map, reduce } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';


import EXAM_SUBJECTS from 'constants/exam-subjects';

let EXAM_SUBJECT_LABELS = _.map(EXAM_SUBJECTS.labels_66, (value, key) => ({
  key,
  label: value,
  index: EXAM_SUBJECTS.index[key],
}));
EXAM_SUBJECT_LABELS = _.orderBy(EXAM_SUBJECT_LABELS, 'index');

moment.locale('th');



export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const subjects = useSelector((state) => state.subjects || []);

  const [stats, setStats] = useState({});
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {

      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });

    } catch (error) {
      console.log('errror', error);

    }
  };

  const sortVetoCount = (data) => {
    const dataAsArray = _.map(data, (value, key) => {
      return { questionNum: key, count: parseInt(value.count) };
    });
    console.log(dataAsArray)

    return _.sortBy(dataAsArray, 'count').reverse();
  };

  const fetchDashboardStat = async () => {
    try {
      setLoading(true);
      const { data } = await callApi({
        url: '/exam-disputes/stats',
      });
      const mappingData = data.map(subjectData => {
        subjectData.set1 = sortVetoCount(subjectData.set1).slice(0, 5)
        subjectData.set2 = sortVetoCount(subjectData.set2).slice(0, 5)
        return subjectData
      })
      setStats(_.keyBy(mappingData, 'subject_name'));
      setLoading(false);
    } catch (error) {
      console.log('errror', error);
      setStats({});
      setLoading(false);
    }
  }


  useEffect(() => {
    if (me) {
      fetchSubjects();
      fetchDashboardStat();
    }
  }, [me]);

  const reportsByDate = [];

  for (let i = 18; i <= 30; i++) {
    if (i === 18) {
      reportsByDate.push({
        date: `${i} เมษายน 2568`,
        title: `รายงานประจำวันที่ ${i} เมษายน 2568`,
        link: 'https://my-tcas.s3.ap-southeast-1.amazonaws.com/examples/exam-report-18-04.csv',
      });
    }
  }

  const header = (subject, count) => {
    const subName = subject.label.replace('A-Level ', '');
    return (
      <Link to={`/exam-vetos/list?subject=${subject.key}`}>
        <div>
          <h3>A-Level</h3>
          <small>{subName}</small>
        </div>
        <strong>{count}</strong>
      </Link>
    );
  };

  const cardContent = (data, setNumber) => {
    return (
      <table className="card-table">
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>ชุดที่ {setNumber}</th>
            <th style={{ textAlign: 'right' }}>{data[`set${setNumber}Count`]}</th>
          </tr>
        </thead>
        <tbody>
          {data[`set${setNumber}`].map(({ questionNum, count }) => (
            <tr key={questionNum}>
              <td style={{ textAlign: 'left' }}>ข้อที่ {questionNum}</td>
              <td style={{ textAlign: 'right' }}>{count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const displaySubjectCards = EXAM_SUBJECT_LABELS.filter((SUBJECT) => {
    return stats[SUBJECT.key];
  });


  return (
    <div className="exam-vetos">
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>การโต้แย้ง</h1>
          <>
            <Row gutter={[16, 16]}>
              {displaySubjectCards.map((SUBJECT) => (
                <>
                  <Col span={6}>
                    <Card title={header(SUBJECT, stats[SUBJECT.key].count)}>
                      {cardContent(stats[SUBJECT.key], '1')}
                      <hr></hr>
                      {cardContent(stats[SUBJECT.key], '2')}
                    </Card>
                  </Col>
                </>
              ))}
            </Row>
          </>
        </div>
      </Spin>
    </div>
  );
};
