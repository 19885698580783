import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import callApi from './helpers/callapi';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { getTheme, viewportWidth } from 'helpers/helpers';
import { Route, Switch, Redirect, useLocation, useHistory, Link } from 'react-router-dom';

import AdminList from 'containers/Admin/List';
import cn from 'classnames';
// import UserList from 'containers/User/List';

import Dashboard from 'containers/Dashboard';
import AdminDashboard from 'containers/Dashboard/Admin';
import Rounds from 'containers/Rounds';
import Programs from 'containers/Programs';
import ProgramsUpdate from 'containers/Programs/update';
import ProgramDetail from 'containers/Programs/Detail';

// import LogList from 'containers/Log/List';
// import ConfigList from 'containers/Config/List';
import EnrollmentImport from 'containers/Enrollment/Import';
import EnrollmentList from 'containers/Enrollment/List';
import EnrollmentDownload from 'containers/Enrollment/Download';
import DisqualifiedList from 'containers/Disqualify/List';
import StudentList from 'containers/Student/List';
import CovidList from 'containers/Covid/List';
import ObecList from 'containers/Student/Raws/Obec';
import ObecPast from 'containers/Student/Raws/ObecPast';
import TCASPastList from 'containers/Student/Raws/TcasPast';
import StudentDetail from 'containers/Student/Detail';
import StudentCheckStatus from 'containers/Student/CheckStatus';
import ScoreList from 'containers/Score/List';
import SubjectList from 'containers/Subject/List';
import LogList from 'containers/Log/List';
import ProgramConditionList from 'containers/ProgramCondition/List';
// import ProgramRound3Dashboard from 'containers/Program/Round3-List';
import TicketList from 'containers/Ticket/List';
import ExamEnquiryList from 'containers/ExamEnquiry/List';
import ExamList from 'containers/ExamEnquiry/AllExams';
import ExamReports from 'containers/ExamEnquiry/Report';

import ExamVetoDashboard from 'containers/ExamVeto/Dashboard';
import ExamVetoList from 'containers/ExamVeto/List';

import OrderList from 'containers/Order/List';
import EditUser from 'containers/Admin/Edit';
import Help from 'containers/Help';
import Contact from 'containers/Contact';
import ConfigPage from 'containers/Config/List';

import SchoolList from 'containers/School/List';
import SchoolDownloadEnrollment from 'containers/School/DownloadEnrollment';

// DEMO
import Demo from 'containers/Demo/demo';

// Containers

// import 'react-big-calendar/lib/css/react-big-calendar.css';

// Components
import { Spin, Modal } from 'antd';
import { UserOutlined, SettingOutlined, BarsOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { useIdleTimer } from 'react-idle-timer';

// menus
import menus from './configs/menus';

moment.locale('th');
const MOBILE_MENU_WIDTH = 240;

const notRedirectToLoginPaths = ['/reset-password', '/credential', '/forgot-password'];

export default function () {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [smallNav, setNav] = useState(false);

  const [isLogoutModalShow, setIsLogoutModalShow] = useState(false);
  const adminConfig = useSelector((state) => state.configs || {});

  const handleOnIdle = (event) => {
    if (location.pathname === '/credential') return null;
    if (location.pathname === '/reset-password') return null;

    setIsLogoutModalShow(true);

    if (!isLogoutModalShow) {
      const accessToken = Cookies.get('accessToken');
      Cookies.remove('accessToken');
      Modal.warning({
        title: '',
        content: (
          <div>
            ท่านไม่ได้ดำเนินการใด ๆ ในระบบเป็นเวลา 10 นาที ท่านจะถูก log off โดยอัตโนมัติ ถ้าท่านต้องการใช้งาน กรุณา
            login อีกครั้ง
          </div>
        ),
        onOk() {
          Cookies.set('accessToken', accessToken);
          setIsLogoutModalShow(false);
          _onLogout();
        },
        cancelButtonProps: <span></span>,
      });
    }
  };

  const handleOnActive = (event) => {
    if (location.pathname === '/credential') return null;
    if (location.pathname === '/reset-password') return null;

    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      Cookies.set('accessToken', accessToken, {
        expires: 1 / (24 * 6),
      });
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    onAction: handleOnActive,
    debounce: 500,
  });

  const handleNav = () => {
    setNav(!smallNav);
  };

  const [currentAdmin, setCurrentAdmin] = useState();
  const [ticketCount, setCountTicket] = useState(0);

  useEffect(() => {
    const getCountTicket = async () => {
      const { count } = await callApi({ url: '/tickets/count' });
      setCountTicket(count);
    };
    const getAdminConfig = async () => {
      const configs = await callApi({ url: '/configs/admin' });
      dispatch({ type: 'UPDATE_CONFIGS', data: configs });
    };
    const verify = async () => {
      try {
        const me = await callApi({ url: '/users/me' });
        if (me.role === 'center') {
          Cookies.remove('accessToken');
          setTimeout(() => {
            window.location.href = 'https://center.t66internal.com/credential';
          }, 1);
          return;
        }

        if (me.role === 'judge') {
          Cookies.remove('accessToken');
          setTimeout(() => {
            window.location.href = 'https://www.mytcas.com';
          }, 1);
          return;

        }



        dispatch({ type: 'UPDATE_USER', data: me });

        setCurrentAdmin(me);
        await getAdminConfig();

        if (me.role !== 'staff') {
          getCountTicket();
        }
      } catch (error) {
        // Cookies.remove('accessToken');
        dispatch({ type: 'UPDATE_USER', data: null });
        if (notRedirectToLoginPaths.indexOf(location.pathname) === -1) {
          // console.log('check');
          window.location.href = 'https://mytcas.com';
        }
      }
    };
    verify();
  }, []);

  const [loggingOut, setLoggingOut] = useState(false);
  const _onLogout = async () => {
    if (loggingOut) return;
    try {
      setLoading(true);
      setLoggingOut(true);
      await callApi({
        method: 'POST',
        url: '/users/logout',
      });

      Cookies.remove('accessToken');
      window.location.href = '/credential';
      setLoggingOut(false);
    } catch (error) {
      console.error('Error while trying to log out.', error);
      window.location.href = '/credential';
      setLoggingOut(false);
    }
  };

  if (location.pathname === '/credential') return null;
  if (location.pathname === '/reset-password') return null;
  if (!currentAdmin) return null;

  if (!Cookies.get('accessToken')) {
    // window.location.href = 'https://mytcas.com';
    return null;
  }

  const checkMenuVisibleFromConfig = (configVisibleField) => {
    if (configVisibleField) {
      if (configVisibleField.startsWith('!')) {
        return !adminConfig[_.trim(configVisibleField, '!')];
      }
      return adminConfig[configVisibleField];
    }
    return true;
  };

  return (
    <section className={smallNav ? 'small-nav' : null}>
      <Spin spinning={loading}>
        <div>
          {location.pathname !== '/credential' && (
            <div className="t-head">
              <h3 className="university">{currentAdmin.role === 'staff' && currentAdmin.university_name}</h3>
              <ul className="t-user">
                <li className="info">
                  <a>
                    {currentAdmin && (currentAdmin.name || currentAdmin.email)}
                    <b>{currentAdmin && currentAdmin.role === 'super' && '• ผู้ดูแลระบบ'}</b>
                  </a>
                </li>
                <li>
                  <a className="btn-gray" style={{ cursor: 'pointer' }} onClick={_onLogout}>
                    ออกจากระบบ
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div className="t-body">
            <div className="t-side">
              <ul className="t-nav">
                <li className="m-home">
                  <Link to="/dashboard">
                    <img src="/img/logo.svg" alt="Logo" width="118" height="44" />
                    <b>ADMIN</b>
                  </Link>
                </li>
                {currentAdmin &&
                  menus.map((menu) => {
                    let { key, to, title, roles = [], submenus = [] } = menu;
                    const currentRole = currentAdmin.role;
                    const isActive =
                      location.pathname === to ||
                      submenus.reduce((result, submenu) => {
                        result = result || location.pathname === submenu.to;
                        return result;
                      }, false);

                    if (roles.length === 0 || roles.indexOf(currentRole) > -1) {
                      return (
                        <li className={cn({ active: isActive }, key)} key={key}>
                          {
                            <Link to={to}>
                              <i className={key}></i>
                              <span>{title}</span>
                              {key === 'tickets' && ticketCount > 0 && <b className="noti">{ticketCount}</b>}
                            </Link>
                          }

                          {Boolean(submenus.length) && isActive && (
                            <ul className="sub">
                              {submenus.map(({ to, title, key, roles = [], configVisibleField }) => {
                                if (
                                  (roles.length === 0 || roles.indexOf(currentRole) > -1) &&
                                  checkMenuVisibleFromConfig(configVisibleField)
                                ) {
                                  return (
                                    <li
                                      className={cn(
                                        {
                                          active: location.pathname === to,
                                        },
                                        key
                                      )}
                                      key={key}
                                    >
                                      <Link to={to}>
                                        <i className={key}></i>
                                        <span>{title}</span>
                                      </Link>
                                    </li>
                                  );
                                }
                                return null;
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    }
                    return <div key={key}></div>;
                  })}

                <li className="m-collapse">
                  <a onClick={handleNav}>
                    <i className="collapse"></i>
                    <span>ย่อเมนู</span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="t-content">
              <Switch>
                <Route exact path="/demo" component={Demo} />
                <Route
                  exact
                  path="/dashboard"
                  render={() => {
                    return get(currentAdmin, 'role') === 'staff' ? <Dashboard /> : <AdminDashboard />;
                  }}
                />
                <Route exact path="/config" component={ConfigPage} />

                <Route exact path="/obec" component={ObecList} />
                <Route exact path="/obec-past" component={ObecPast} />
                <Route exact path="/tcas-past" component={TCASPastList} />

                <Route exact path="/students" component={StudentList} />
                <Route exact path="/covid" component={CovidList} />
                <Route exact path="/check-status" component={StudentCheckStatus} />
                <Route exact path="/students/:studentId" component={StudentDetail} />

                <Route exact path="/schools" component={SchoolList} />
                <Route exact path="/school-enrollments" component={SchoolDownloadEnrollment} />

                <Route exact path="/scores" component={ScoreList} />

                <Route exact path="/subjects" component={SubjectList} />
                <Route exact path="/logs" component={LogList} />
                <Route exact path="/tickets" component={TicketList} />
                <Route exact path="/exams/enquiries" component={ExamEnquiryList} />
                <Route exact path="/exams/all" component={ExamList} />
                <Route exact path="/exams/report" component={ExamReports} />

                <Route exact path="/exams/enquiries" component={ExamEnquiryList} />
                <Route exact path="/exams/all" component={ExamList} />
                <Route exact path="/exams/report" component={ExamReports} />

                <Route exact path="/exam-vetos" component={ExamVetoDashboard} />
                <Route exact path="/exam-vetos/list" component={ExamVetoList} />
                <Route exact path="/orders" component={OrderList} />
                <Route exact path="/users" component={AdminList} />
                <Route exact path="/users/edit" component={EditUser} />

                <Route exact path="/enrollments" component={EnrollmentList} />
                <Route exact path="/enrollments/download" component={EnrollmentDownload} />

                <Route exact path="/enrollments/disqualify" component={DisqualifiedList} />
                <Route
                  exact
                  path="/enrollments/import"
                  render={() => {
                    return get(currentAdmin, 'role') === 'staff' ? (
                      <EnrollmentImport />
                    ) : (
                      <Redirect to="/enrollments" />
                    );
                  }}
                />

                <Route
                  exact
                  path="/programs/import"
                  render={() => {
                    return get(currentAdmin, 'role') === 'staff' ? <Programs /> : <Redirect to="/programs" />;
                  }}
                />

                <Route
                  exact
                  path="/programs/update"
                  render={() => {
                    return get(currentAdmin, 'role') === 'staff' ? <ProgramsUpdate /> : <Redirect to="/programs" />;
                  }}
                />
                <Route exact path="/programs" component={ProgramConditionList} />

                <Route exact path="/programs/:programId" component={ProgramDetail} />

                <Route exact path="/help" component={Help} />

                <Route exact path="/contact" component={Contact} />

                {/* <Redirect to="/credential" /> */}
              </Switch>
            </div>
          </div>
        </div>
      </Spin>
    </section>
  );
}
