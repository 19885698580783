module.exports = {
  labels_66: {
    TGAT: 'ความถนัดทั่วไป (TGAT)',
    TPAT2: 'ความถนัดศิลปกรรมศาสตร์ (TPAT2)',
    TPAT3: 'ความถนัดวิทยาศาสตร์ เทคโนโลยี วิศวกรรมศาสตร์ (TPAT3)',
    TPAT4: 'ความถนัดสถาปัตยกรรมศาสตร์ (TPAT4)',
    TPAT5: 'ความถนัดครุศาสตร์-ศึกษาศาสตร์ (TPAT 5)',
    Math1: 'A-Level คณิตศาสตร์ประยุกต์ 1 (พื้นฐาน+เพิ่มเติม)',
    Math2: 'A-Level คณิตศาสตร์ประยุกต์ 2 (พื้นฐาน)',
    Sci: 'A-Level วิทยาศาสตร์ประยุกต์',
    Phy: 'A-Level ฟิสิกส์',
    Chem: 'A-Level เคมี',
    Bio: 'A-Level ชีววิทยา',
    Soc: 'A-Level สังคมศึกษา',
    Thai: 'A-Level ภาษาไทย',
    Eng: 'A-Level ภาษาอังกฤษ',
    Fra: 'A-Level ภาษาฝรั่งเศส',
    Deu: 'A-Level ภาษาเยอรมัน',
    Jpn: 'A-Level ภาษาญี่ปุ่น',
    Kor: 'A-Level ภาษาเกาหลี',
    Chn: 'A-Level ภาษาจีน',
    Bal: 'A-Level ภาษาบาลี',
    Esp: 'A-Level ภาษาสเปน',
  },
  a_lv_keys: {
    Math1: 'a_lv_61',
    Math2: 'a_lv_62',
    Sci: 'a_lv_63',
    Phy: 'a_lv_64',
    Chem: 'a_lv_65',
    Bio: 'a_lv_66',
    Soc: 'a_lv_70',
    Thai: 'a_lv_81',
    Eng: 'a_lv_82',
    Fra: 'a_lv_83',
    Deu: 'a_lv_84',
    Jpn: 'a_lv_85',
    Kor: 'a_lv_86',
    Chn: 'a_lv_87',
    Bal: 'a_lv_88',
    Esp: 'a_lv_89',
  },
  a_lv_codes: {
    Math1: '61',
    Math2: '62',
    Sci: '63',
    Phy: '64',
    Chem: '65',
    Bio: '66',
    Soc: '70',
    Thai: '81',
    Eng: '82',
    Fra: '83',
    Deu: '84',
    Jpn: '85',
    Kor: '86',
    Chn: '87',
    Bal: '88',
    Esp: '89',
  },


  labels: {
    TGAT: 'ความถนัดทั่วไป (TGAT)',
    TPAT2: 'ความถนัดศิลปกรรมศาสตร์ (TPAT2)',
    TPAT3: 'ความถนัดวิทยาศาสตร์ เทคโนโลยี วิศวกรรมศาสตร์ (TPAT3)',
    TPAT4: 'ความถนัดสถาปัตยกรรมศาสตร์ (TPAT4)',
    TPAT5: 'ความถนัดครุศาสตร์-ศึกษาศาสตร์ (TPAT 5)',

    GAT: 'ความถนัดทั่วไป (GAT)',
    PAT1: 'ความถนัดทางคณิตศาสตร์ (PAT 1)',
    PAT2: 'ความถนัดทางวิทยาศาสตร์ (PAT 2)',
    PAT3: 'ความถนัดทางวิศวกรรมศาสตร์ (PAT 3)',
    PAT4: 'ความถนัดทางสถาปัตยกรรมศาสตร์ (PAT 4)',
    PAT5: 'ความถนัดทางวิชาชีพครู (PAT 5)',
    PAT6: 'ความถนัดทางศิลปกรรมศาสตร์ (PAT 6)',
    'PAT7.1': 'ความถนัดทางภาษาฝรั่งเศส (PAT 7.1)',
    'PAT7.2': 'ความถนัดทางภาษาเยอรมัน (PAT 7.2)',
    'PAT7.3': 'ความถนัดทางภาษาญี่ปุ่น (PAT 7.3)',
    'PAT7.4': 'ความถนัดทางภาษาจีน (PAT 7.4)',
    'PAT7.5': 'ความถนัดทางภาษาอาหรับ (PAT 7.5)',
    'PAT7.6': 'ความถนัดทางภาษาบาลี (PAT 7.6)',
    'PAT7.7': 'ความถนัดทางภาษาเกาหลี (PAT 7.7)',
    '09': 'วิชาสามัญ ภาษาไทย',
    19: 'วิชาสามัญ สังคมศึกษา',
    29: 'วิชาสามัญ ภาษาอังกฤษ',
    39: 'วิชาสามัญ คณิตศาสตร์ 1',
    49: 'วิชาสามัญ ฟิสิกส์',
    59: 'วิชาสามัญ เคมี',
    69: 'วิชาสามัญ ชีววิทยา',
    89: 'วิชาสามัญ คณิตศาสตร์ 2',
    99: 'วิชาสามัญ วิทยาศาสตร์ทั่วไป',
  },
  scores: {
    TGAT: 'tgat',
    TPAT2: 'tpat2',
    TPAT3: 'tpat3',
    TPAT4: 'tpat4',
    TPAT5: 'tpat5',
    Math1: 'a_lv_61',
    Math2: 'a_lv_62',
    Sci: 'a_lv_63',
    Phy: 'a_lv_64',
    Chem: 'a_lv_65',
    Bio: 'a_lv_66',
    Soc: 'a_lv_70',
    Thai: 'a_lv_81',
    Eng: 'a_lv_82',
    Fra: 'a_lv_83',
    Deu: 'a_lv_84',
    Jpn: 'a_lv_85',
    Kor: 'a_lv_86',
    Chn: 'a_lv_87',
    Bal: 'a_lv_88',
    Esp: 'a_lv_89',

    GAT: 'gat',
    PAT1: 'pat1',
    PAT2: 'pat2',
    PAT3: 'pat3',
    PAT4: 'pat4',
    PAT5: 'pat5',
    PAT6: 'pat6',
    'PAT7.1': 'pat7_1',
    'PAT7.2': 'pat7_2',
    'PAT7.3': 'pat7_3',
    'PAT7.4': 'pat7_4',
    'PAT7.5': 'pat7_5',
    'PAT7.6': 'pat7_6',
    'PAT7.7': 'pat7_7',
    '09': '9sub_09',
    19: '9sub_19',
    29: '9sub_29',
    39: '9sub_39',
    49: '9sub_49',
    59: '9sub_59',
    69: '9sub_69',
    89: '9sub_89',
    99: '9sub_99',
  },
  index: {
    TGAT: 1,
    TPAT2: 2,
    TPAT3: 3,
    TPAT4: 4,
    TPAT5: 5,

    GAT: 1,
    PAT1: 2,
    PAT2: 3,
    PAT3: 4,
    PAT4: 5,
    PAT5: 6,
    PAT6: 7,
    PAT7: 8,
    'PAT7.1': 9,
    'PAT7.2': 10,
    'PAT7.3': 11,
    'PAT7.4': 12,
    'PAT7.5': 13,
    'PAT7.6': 14,
    'PAT7.7': 15,
    '09': 16,
    19: 17,
    29: 18,
    39: 19,
    49: 20,
    59: 21,
    69: 22,
    89: 23,
    99: 24,
  },
  maxScore: {
    TGAT: 100,
    TGAT1: 100,
    TGAT2: 100,
    TGAT3: 100,
    TPAT2: 100,
    TPAT21: 100,
    TPAT22: 100,
    TPAT23: 100,
    TPAT3: 100,
    TPAT4: 100,
    TPAT5: 100,

    Math1: 100,
    Math2: 100,
    Sci: 100,
    Phy: 100,
    Chem: 100,
    Bio: 100,
    Soc: 100,
    Thai: 100,
    Eng: 100,
    Fra: 100,
    Deu: 100,
    Jpn: 100,
    Kor: 100,
    Chn: 100,
    Bal: 100,
    Esp: 100,

    GAT: 300,
    PAT1: 300,
    PAT2: 300,
    PAT3: 300,
    PAT4: 300,
    PAT5: 300,
    PAT6: 300,
    PAT7: 300,
    'PAT7.1': 300,
    'PAT7.2': 300,
    'PAT7.3': 300,
    'PAT7.4': 300,
    'PAT7.5': 300,
    'PAT7.6': 300,
    'PAT7.7': 300,
    '09': 100,
    19: 100,
    29: 100,
    39: 100,
    49: 100,
    59: 100,
    69: 100,
    89: 100,
    99: 100,
  },
};
