import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, map, reduce } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead, { ContentHeadWrapper } from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  message,
  Input,
  Upload,
  Radio,
  Spin,
  Collapse,
} from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';
import { download } from 'helpers/download';
import fetchJson from 'helpers/fetchjson';

import Dotdotdot from 'react-dotdotdot'

import EXAM_SUBJECTS from 'constants/exam-subjects';
import DetailModal from './DetailModal';
const { a_lv_codes } = EXAM_SUBJECTS;


let EXAM_SUBJECT_LABELS = _.map(EXAM_SUBJECTS.labels_66, (value, key) => ({
  key,
  label: value,
  index: EXAM_SUBJECTS.index[key],
}));
EXAM_SUBJECT_LABELS = _.orderBy(EXAM_SUBJECT_LABELS, 'index');
EXAM_SUBJECT_LABELS = EXAM_SUBJECT_LABELS.filter((item) => !item.key.includes('TGAT') && !item.key.includes('TPAT'));

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const Option = Select.Option;

const sortVetoCount = (data) => {
  const dataAsArray = _.map(data, (value, key) => {
    return { questionNum: key, veto: value };
  });
  return dataAsArray;
  // return _.sortBy(dataAsArray, 'count').reverse();
};

const getParam = (paramaName) => {
  const params = new URLSearchParams(window.location.search);
  const subjectParam = params.get(paramaName);
  return subjectParam;
};

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const subjects = useSelector((state) => state.subjects || []);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedVeto, setSelectedVeto] = useState({});
  // const loaded = useSelector((state) => state.coursesLoaded);

  const [loading, setLoading] = useState(false);

  const [vetos, setVetos] = useState([]);
  const [vetoCount, setVetoCount] = useState(0);
  const [stats, setStats] = useState({});
  const [keys, setKeys] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    citizen_id: '',
    app_id: '',
    set: 'all',
    subject: 'all',
  });

  const fetchDashboardStat = async () => {
    try {
      const { data } = await callApi({
        url: '/exam-disputes/stats',
      });


      const mappingData = data.map(subjectData => {
        subjectData.set1 = sortVetoCount(subjectData.set1)
        subjectData.set2 = sortVetoCount(subjectData.set2)
        return subjectData
      })
      setStats(_.keyBy(mappingData, 'subject_name'));


    } catch (error) {
      console.log('errror', error);
      setStats({});
    }
  }

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const fetchKeys = async (page, subject) => {
    try {

      let query = { page: page || 1, ...filterQuery };
      if (subject) {
        query.subject = subject;
      }

      query = _.pickBy(query, value => value && value !== 'all');

      const { url } = await callApi({
        url: '/exam-disputes/keys',
        query,
      });
      const data = await fetchJson(url);
      console.log('data', data);
      setKeys(data);

    } catch (error) {
      console.log('errror', error);


    }
  };


  const fetchVetos = async (page, subject) => {
    try {
      setLoading(true);

      let query = { page: page || 1, ...filterQuery };
      if (subject) {
        query.subject = subject;
      }

      query = _.pickBy(query, value => value && value !== 'all');

      const { count, data: vetoList } = await callApi({
        url: '/exam-disputes',
        query,
      });


      setVetos(vetoList || []);
      setVetoCount(count);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setVetos([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me) {
      fetchSubjects();
      fetchKeys()
    }
  }, [me]);

  useEffect(() => {
    // Get URL parameters when component mounts
    const subjectParam = getParam('subject');

    // Set the filter query if subject parameter exists
    if (subjectParam) {
      setFilterQuery({
        ...filterQuery,
        subject: subjectParam,
      });
    }
    // fetchapps(1);
    fetchVetos(1, subjectParam);
    fetchDashboardStat();
  }, []);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchVetos(_page);
  };

  // if (me) {
  //   currentCourses = courses[me.university_id];
  // }
  const onSetChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      set: value,
    });
  };

  const onSubjectChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      subject: value,
    });
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchVetos(1);
  };

  const openLink = (id) => async () => {
    setLoading(true);
    const { url } = await callApi({
      url: `/exam-disputes/${id}/files`,

    });
    window.open(url, '_blank');
    setLoading(false);
  }
  const formatAnswer = (answer) => {
    if (answer === 'unanswerable') {
      return 'ไม่มีคำตอบ'
    }
    return answer;
  }

  const displaySubjectCards = stats[filterQuery.subject];

  const getKey = (subject, setNumber, question_id) => {
    const subjectKeys = keys[a_lv_codes[subject]];
    const subjectKeySet = _.get(subjectKeys, setNumber);


    return _.get(subjectKeySet, `Q${question_id}`);
  }

  const summaryContent = (data, setNumber) => {
    // console.log(Object.entries(data[`set${setNumber}`]));
    return (
      <>
        <table className="">
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>ชุดที่ {setNumber}</th>
              <th style={{ textAlign: 'right' }}>จำนวน</th>
              <th style={{ textAlign: 'center' }}>เฉลย</th>
              <th style={{ textAlign: 'center' }}>โต้แย้ง 1</th>
              <th style={{ textAlign: 'center' }}>โต้แย้ง 2</th>
            </tr>
          </thead>
          <tbody>
            {data[`set${setNumber}`].map(({ questionNum, veto }) => {
              const count = _.get(veto, 'count')

              const key = getKey(filterQuery.subject, setNumber, questionNum);



              return (
                <tr key={questionNum}>
                  <td style={{ textAlign: 'left' }}>ข้อที่ {questionNum}</td>
                  <td style={{ textAlign: 'right' }}>{count}</td>
                  <td style={{ textAlign: 'center' }}>{key}</td>
                  <td style={{ textAlign: 'center' }}>{formatAnswer(_.get(veto, 'top1.answer'))} ({Math.round((_.get(veto, 'top1.count') / count) * 100)}%)</td>
                  <td style={{ textAlign: 'center' }}>{formatAnswer(_.get(veto, 'top2.answer'))} ({Math.round((_.get(veto, 'top2.count') / count) * 100)}%)</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>ข้อมูลคำร้อง</h1>

          <div className="sec-box">
            <h2>ค้นหาข้อมูลคำร้อง</h2>
            <div className="sec-form">
              <div className="col-20">
                <label>รหัสประจำตัวประชาชน</label>
                <div className="ant-text">
                  <Input
                    value={get(filterQuery, 'citizen_id')}
                    onChange={(e) =>
                      setFilterQuery({
                        ...filterQuery,
                        citizen_id: e.target.value,
                      })
                    }
                    onPressEnter={onSearch}
                    type="text"
                  />
                </div>
              </div>
              <div className="col-20">
                <label>ที่นั่งสอบ</label>
                <div className="ant-text">
                  <Input
                    value={get(filterQuery, 'app_id')}
                    onChange={(e) =>
                      setFilterQuery({
                        ...filterQuery,
                        app_id: e.target.value,
                      })
                    }
                    onPressEnter={onSearch}
                    type="text"
                  />
                </div>
              </div>
              {/* <div className="col-20">
                <label>ชื่อ, นามสกุล, ที่นั่งสอบ</label>
                <div className="ant-text">
                  <Input
                    value={get(filterQuery, 'q')}
                    onChange={(e) =>
                      setFilterQuery({
                        ...filterQuery,
                        q: e.target.value,
                      })
                    }
                    onPressEnter={onSearch}
                    type="text"
                  />
                </div>
              </div> */}

              <div className="col-20">
                <label>
                  วิชา
                  <div className="select-container">
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onSubjectChange}
                      value={get(filterQuery, 'subject')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value={'all'} label={'ทั้งหมด'}>
                        ทั้งหมด
                      </Option>
                      {_.map(EXAM_SUBJECT_LABELS, (EXAM_SUBJECT_LABEL, key) => {
                        return (
                          <Option key={key} value={EXAM_SUBJECT_LABEL.key} label={EXAM_SUBJECT_LABEL.label}>
                            {EXAM_SUBJECT_LABEL.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-10">
                <label>
                  ชุดที่
                  <div className="select-container">
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={onSetChange}
                      value={get(filterQuery, 'set')}
                      filterOption={filterLabelAndValue}
                    >
                      <Option key={'all'} value={'all'} label={'all'}>
                        ทั้งหมด
                      </Option>

                      {['1', '2'].map((set) => (
                        <Option key={set} value={set} label={set}>
                          ชุดที่ {set}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-20 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchVetos(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {filterQuery.subject !== 'all' && displaySubjectCards && (
            <>
              <div className="site-collapse-custom-collapse">
                <Collapse defaultActiveKey={[]} ghost>
                  <Collapse.Panel header={`สรุปยอดคำร่้องโต้แย้ง (${displaySubjectCards.count})`} key="1">
                    <Row gutter={[24, 24]}>
                      <Col span={12}>{summaryContent(displaySubjectCards, '1')}</Col>
                      <Col span={12}>{summaryContent(displaySubjectCards, '2')}</Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
                <br></br>
              </div>

              <div className="export-section" style={{ marginBottom: '20px', textAlign: 'right' }}>
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const query = { ...filterQuery, export: true };
                      const response = await callApi({
                        url: `/exam-disputes/${filterQuery.subject}/exports`,

                      });
                      if (response.url) {
                        download(response.url);
                      }

                      message.success('ดาวน์โหลดข้อมูลสำเร็จ');
                    } catch (error) {
                      console.error('Export error:', error);
                      message.error('เกิดข้อผิดพลาดในการดาวน์โหลดข้อมูล');
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  ส่งออกข้อมูล
                </Button>
              </div>
            </>
          )}




          {vetos.length === 0 && (
            <table className="t-table -examApplications">
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลเงื่อนไขการรับสมัครในระบบ</th>
                </tr>
              </tbody>
            </table>
          )}
          {vetos.length > 0 && (
            <>
              <table className="t-table -examApplications">
                <thead>
                  <tr>
                    <th>หมายเลขที่นั่ง</th>
                    <th>วิชา</th>
                    <th>ชุดที่</th>
                    <th>ข้อที่</th>
                    <th>เฉลย</th>
                    <th>โต้แย้ง</th>
                    <th>คำอธิบาย</th>
                    <th>ไฟล์แนบ</th>
                    <th>เวลาสร้าง</th>
                  </tr>
                </thead>
                <tbody>
                  {vetos.map((veto, i) => {
                    const key = getKey(veto.subject_name, veto.set, veto.question_id);


                    return (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            setSelectedVeto(veto);
                            setDetailModalVisible(true);
                          }}
                        >
                          <a>{veto.app_id}</a>
                        </td>
                        <td>{EXAM_SUBJECTS.labels_66[veto.subject_name]}</td>

                        <td>{veto.set}</td>
                        <td>{veto.question_id}</td>
                        <td>{key}</td>
                        <td>{formatAnswer(veto.answer)}</td>
                        <td>
                          <Dotdotdot clamp={3}>
                            {veto.reason}
                          </Dotdotdot>
                        </td>
                        <td>
                          {veto.attached_image_path ? (
                            <a onClick={openLink(veto._id)} target="_blank" rel="noopener noreferrer">
                              <i className="i-book-yes" aria-hidden="true" style={{ height: '24px', width: '24px' }}></i>
                            </a>
                          ) : (
                            ''
                          )}

                        </td>

                        <td>{moment(veto.created_at).add('543', 'year').format('D MMM HH:mm น.')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={40}
                total={vetoCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin >

      {detailModalVisible && (
        <DetailModal
          isVisible={detailModalVisible}
          setIsVisible={setDetailModalVisible}
          examVeto={selectedVeto}
          getKey={getKey}
          onClose={() => {
            setSelectedVeto({});
            setDetailModalVisible(false);
          }}
        />
      )
      }
    </div >
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
