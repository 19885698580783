import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import { Tabs, Collapse } from 'antd';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, map, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';
import cn from 'classnames';
import TH from 'constants/th';
const { Panel } = Collapse;
import EXAM_SUBJECTS from 'constants/exam-subjects';
// import AnswerTable from './AnswerTable';

// Components
import CONSTANTS from 'constants/constants';
import ContentHead from 'components/ContentHead';
import {
  Button,
  Table,
  Pagination,
  Modal,
  Row,
  Col,
  Select,
  Form,
  message,
  Input,
  InputNumber,
  Popconfirm,
} from 'antd';
import { file } from '@babel/types';
import { applyMiddleware } from 'redux';
// import TICKET_CATEGORIES from 'constants/examVeto-types.json';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// const TICKET_LABELS = TICKET_CATEGORIES.reduce((result, category) => {
//   result[category.value] = category.label;
//   return result;
// }, {});

const programCodeName = {
  1: 'หลักสูตรแกนกลาง (สามัญ)',
  2: 'หลักสูตรนานาชาติ',
  3: 'หลักสูตรอาชีวะ',
  4: 'หลักสูตรตามอัธยาศัย (กศน.)',
  5: 'หลักสูตรนานาชาติจบจากต่างประเทศ',
  6: 'สอบเทียบ GED',
};

const schoolHiddenFields = [
  'school_code',
  'school_zipcode',
  'school_province_id',
  'school_province_name',
  'school_amph_id',
  'school_amph_name',
  'school_tmbl_id',
  'school_tmbl_name',
];

export default ({ examVeto, setIsVisible, getKey, onClose, isVisible, notShowButton }) => {
  const [loading, setLoading] = useState(false);
  const [fullVeto, setFullVeto] = useState();
  const key = getKey(examVeto.subject_name, examVeto.set, examVeto.question_id);

  useEffect(() => {
    const fetchfullVeto = async () => {
      try {
        setLoading(true);
        const veto = await callApi({ url: `/exam-disputes/${examVeto._id}` });
        setFullVeto(veto);
        setLoading(false);
      } catch (error) {
        console.error('Error while trying to fetch orders.', error);
        message.error(get(error, 'reason.message'));
        setFullVeto(examVeto);
        setLoading(false);
      }
    };
    if (examVeto && examVeto._id) {
      fetchfullVeto();
    }
  }, [examVeto]);

  if (!examVeto) {
    return null;
  }
  let title = EXAM_SUBJECTS.labels[examVeto.subject_name];

  if (!fullVeto) {
    return <></>;
  }

  const formatAnswer = (answer) => {
    if (answer === 'unanswerable') {
      return 'ไม่มีคำตอบ'
    }
    return answer;
  }

  let footer = [];

  return (
    <Modal
      title={title}
      visible={isVisible}
      width={1200}
      footer={footer}
      onCancel={() => setIsVisible(false)}
    >
      <div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>เลขที่นั่ง</td>
                <td>{examVeto.app_id} </td>
              </tr>
              <tr>
                <td>รหัสบัตรประชาชน</td>
                <td>{examVeto.citizen_id}</td>
              </tr>
              <tr>
                <td>ชื่อ</td>
                <td>{`${get(fullVeto, `student.first_name`)} ${get(fullVeto, `student.last_name`)} `}</td>
              </tr>
              <tr>
                <td>ชุดที่</td>
                <td>{examVeto.set}</td>
              </tr>

              <tr>
                <td>ข้อที่</td>
                <td>{examVeto.question_id}</td>
              </tr>
              <tr>
                <td>เฉลย</td>
                <td>{key}</td>
              </tr>

              <tr>
                <td>เฉลยควรเป็น</td>
                <td>{formatAnswer(examVeto.answer)}</td>
              </tr>


              <tr>
                <td>คำอธิบาย</td>
                <td>{examVeto.reason}</td>
              </tr>

              {fullVeto.attached_image_path && <tr>
                <td>ไฟล์แนบ</td>
                <td>

                  <a onClick={() => window.open(fullVeto.attached_image_path, '_blank')} target="_blank" rel="noopener noreferrer">
                    <i className="i-book-yes" aria-hidden="true" style={{ height: '24px', width: '24px' }}></i>
                  </a>



                </td>
              </tr>
              }

            </tbody>
          </table>
        </div>
      </div>
    </Modal >
  );
};
