import React, { useEffect, useState } from 'react';
import { Tabs, Collapse, Spin, Select } from 'antd';

import callApi from 'helpers/callapi';
import { useParams } from 'react-router';
import moment from 'moment';
import _, { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import EXAM_SUBJECTS from 'constants/exam-subjects';

const { Panel } = Collapse;

const Exam = ({ student }) => {
  // let { studentId } = useParams();
  const dispatch = useDispatch();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [types, setTypes] = useState([]);
  // const [subtypes, setSubtypes] = useState([]);
  const [filterQuery, setFilterQuery] = useState({
    type: 'all',
    subtype: 'all',
  });

  const subjects = useSelector((state) => state.subjects || []);

  const fetchSubjects = async () => {
    if (subjects.length > 0) {
      return;
    }
    try {
      const { data } = await callApi({
        url: '/subjects',
      });
      dispatch({ type: 'UPDATE_SUBJECTS', data });
    } catch (error) {
      console.log('errror', error);
    }
  };

  useEffect(() => {
    fetchSubjects();
    fetchLogs();
  }, []);

  // https://tcas65-staging-dot-tcas65.as.r.appspot.com/logs/bq/2275875640141?offset=0&limit=1000
  const fetchLogs = async () => {
    setLoading(true);
    const result = await callApi({
      url: `/logs/bq/${student.citizen_id}?offset=0&limit=1000`,
    });
    setLogs(result.data);
    let _types = _.uniqBy(result.data, 'type').map((log) => log.type);
    _types = _.orderBy(_types);
    // let _subtypes = _.uniqBy(result.data, 'sub_type')
    //   .filter((log) => log.sub_type)
    //   .map((log) => log.sub_type);
    // _subtypes = _.orderBy(_subtypes);
    setTypes(_types);

    setLoading(false);
  };

  useEffect(() => {
    if (student.citizen_id) {
      fetchLogs();
    }
  }, [student]);

  let subtypes = [];
  if (filterQuery.type !== 'all') {
    const logFilterByType = logs.filter((log) => log.type === filterQuery.type);
    subtypes = _.uniqBy(logFilterByType, 'sub_type')
      .filter((log) => log.sub_type)
      .map((log) => log.sub_type);
  }

  let displayLogs = logs;
  if (filterQuery.type !== 'all' || filterQuery.subtype !== 'all') {
    displayLogs = logs.filter((log) => {
      if (filterQuery.subtype === 'all') {
        return log.type === filterQuery.type;
      } else {
        return log.type === filterQuery.type && log.subtype === filterQuery.subtype;
      }
    });
  }

  // console.log(repliesByRound);

  return (
    <Spin spinning={loading}>
      <div>
        <h1 className="t-title">
          {student.citizen_id} {student.title}
          {student.first_name} {student.last_name}
        </h1>

        <div className="sec-box">
          <h2>ตัวกรอง</h2>
          <div className="sec-form">
            <div className="col-25">
              <label>
                รูปแบบการใช้งานหลัก
                <div className="select-container">
                  <Select
                    style={{ width: '100%' }}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) =>
                      setFilterQuery({
                        type: value,
                        subtype: 'all',
                      })
                    }
                    value={get(filterQuery, 'type')}
                  >
                    <Select.Option key={'all'} value={'all'} label={'all'}>
                      {'ทั้งหมด'}
                    </Select.Option>
                    {types.map((type) => (
                      <Select.Option key={type} value={type} label={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </label>
            </div>
            <div className="col-25">
              <label>
                รูปแบบการใช้งานย่อย
                <div className="select-container">
                  <Select
                    style={{ width: '100%' }}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) =>
                      setFilterQuery({
                        ...filterQuery,
                        subtype: value,
                      })
                    }
                    value={get(filterQuery, 'subtype')}
                  >
                    <Select.Option key={'all'} value={'all'} label={'all'}>
                      {'ทั้งหมด'}
                    </Select.Option>
                    {subtypes.map((subtype) => (
                      <Select.Option key={subtype} value={subtype} label={subtype}>
                        {subtype}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </label>
            </div>
          </div>
        </div>

        <>
          <table className="t-table -students">
            <thead>
              <tr>
                <th>เวลา</th>
                <th>ชนิด</th>
                <th>ชนิดย่อย</th>
                <th>IP Address</th>
                <th>เบราเซอร์</th>
                <th>OS</th>
                <th>ข้อมูลเพิ่มเติม</th>
              </tr>
            </thead>
            <tbody>
              {displayLogs.map((log, i) => {
                return (
                  <tr key={i}>
                    <td>{log.timestamp && moment(log.timestamp.value).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{log.type}</td>
                    <td>{log.sub_type}</td>
                    <td
                      style={{
                        whiteSpace: 'normal',
                        width: '150px',
                        wordBreak: 'break-all',
                        wordWrap: 'break-word',
                      }}
                    >
                      {log.ip_address}
                    </td>
                    <td>{log.browser}</td>
                    <td>{log.os}</td>
                    <td>{log.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* <Pagination
          current={currentPage}
          onChange={onChangePage}
          defaultPageSize={20}
          total={studentCount}
          showSizeChanger={false}
        /> */}
        </>
      </div>
    </Spin>
  );
};
export default Exam;
